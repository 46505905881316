import Column from "@/Components/Backend/Grid/Column";
import Container from "@/Components/Backend/Grid/Container";
import Row from "@/Components/Backend/Grid/Row";
import { Link } from "@inertiajs/react";
import { ReactNode } from "react";

const HeroWithBoxes = ({ hero, boxes, texts }: {
    hero: string;
    boxes?: { decor?: boolean, image: string; link?: string, asHash?: boolean, asExternal?: boolean }[]
    texts?: ReactNode
}) => {

    const scrollToElement = (element: string | undefined) => {
        if (!element) return;

        const htmlEl = document.querySelector(element)
        
        if(htmlEl){
            htmlEl.scrollIntoView({ behavior: 'smooth' })
        }
    }

    return (
        <section>
            <div
                className={`w-full relative md:min-h-[705px] h-[300px] bg-no-repeat transition-all ease-in-out duration-150 bg-cover z-0 bg-center ${!boxes ? 'md:min-h-[705px] bg-[25%_70%]' : ''}`}
                style={{ backgroundImage: `url(${hero})` }}
            >
                <div className="px-3 max-w-[calc(100%_-_72px)] w-full mx-auto h-full">
                    <Row className="h-full justify-between">
                        <Column className="flex md:justify-start justify-center" xl={3}>
                            <div className="mx-4 lg:mx-0 bg-white px-6 py-4 w-max rounded-b-[24px] absolute">
                                <img className="h-auto max-w-full" src="/storage/img/logos/unidevelopment-logo-color.svg" alt="unidevelopment" width={250} height={33} />
                            </div>
                        </Column>
                        <Column className="flex-col items-end py-6 gap-y-4 md:flex hidden" xl={3}>
                            {boxes && boxes.map((box, i) => (
                                <div key={i}>
                                    {box.link ?
                                        <>
                                            {box.asHash ?
                                            <button onClick={() => scrollToElement(box.link)}>
                                                <img src={box.image} alt="" className={`w-[200px] h-[200px] shadow-hero-box-shadow ${box.decor && 'border-[10px] border-white'}`} />
                                            </button>
                                             :
                                             <>
                                                {box.asExternal ?
                                                    <a href={box.link} target="_blank" key={i+'ext'}>
                                                        <img src={box.image} alt="" className={`w-[200px] h-[200px] shadow-hero-box-shadow ${box.decor && 'border-[10px] border-white'}`} />
                                                    </a>
                                                :
                                                    <Link href={box.link} key={i+'nonext'}>
                                                        <img src={box.image} alt="" className={`w-[200px] h-[200px] shadow-hero-box-shadow ${box.decor && 'border-[10px] border-white'}`} />
                                                    </Link>
                                                }
                                             </>
                                             }
                                        </>
                                        :
                                        <img key={i} src={box.image} alt="" className={`w-[200px] h-[200px] shadow-hero-box-shadow ${box.decor && 'border-[10px] border-white'}`} />
                                    }
                                </div>
                            ))}
                        </Column>
                    </Row>
                </div>
            </div>
            <Container className="md:hidden block mt-6">
                <Row className="gap-y-4">
                    <Column className="inline-flex overflow-x-auto whitespace-nowrap">
                        {boxes && boxes.map((box, i) => (
                            <div key={i} className="flex-none w-[200px] mr-3 last:mr-0">
                                {box.link ?
                                        <>
                                            {box.asHash ?
                                            <button onClick={() => scrollToElement(box.link)}>
                                                <img src={box.image} alt="" className={`w-[200px] h-[200px] shadow-hero-box-shadow ${box.decor && 'border-[10px] border-white'}`} />
                                            </button>
                                             :
                                             <>
                                                {box.asExternal ?
                                                    <a href={box.link} target="_blank" key={i+'ext'}>
                                                        <img src={box.image} alt="" className={`w-[200px] h-[200px] shadow-hero-box-shadow ${box.decor && 'border-[10px] border-white'}`} />
                                                    </a>
                                                :
                                                    <Link href={box.link} key={i+'nonext'}>
                                                        <img src={box.image} alt="" className={`w-[200px] h-[200px] shadow-hero-box-shadow ${box.decor && 'border-[10px] border-white'}`} />
                                                    </Link>
                                                }
                                             </>
                                             }
                                        </>
                                        :
                                        <img key={i} src={box.image} alt="" className={`w-[200px] h-[200px] shadow-hero-box-shadow ${box.decor && 'border-[10px] border-white'}`} />
                                    }
                            </div>
                        ))}
                    </Column>
                </Row>
            </Container>


            <Container>
                <Row>
                    <Column xl={8} lg={8} md={10}>
                        {texts}
                    </Column>
                </Row>
            </Container>
        </section>
    )
}

export default HeroWithBoxes;